.login {
  margin-top: 150px;
}

.bg-dark {
  background-color: #212529 !important;
}

.page-item .page-link {
  color: #17a2b8;
}
.page-item.active .page-link {
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.action-icon {
  width: 20px;
  margin: 0px 5px;
  cursor: pointer;
}

.sidebar-nav {
  height: 100%;
  width: 180px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #212529;
  overflow: hidden;
  transition: 0.5s;
  padding-top: 60px;
}
.sidebar-nav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 1rem;
  color: #818181;
  display: block;
  transition: 0.3s;
}
.sidebar-nav a .active {
  color: #f1f1f1;
}
.sidebar-nav a:hover {
  color: #f1f1f1;
}
.sidebar-nav i {
  color: #818181;
  cursor: pointer;
}
.sidebar-nav i:hover {
  color: #f1f1f1;
}
.sidebar-nav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
.sidebar-img {
  width: 20px;
  height: 20px;
  margin-left: -30px;
  margin-right: 10px;
}
.sidebar-tab {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-height: 450px) {
  .sidebar-nav {
    padding-top: 15px;
  }
  .sidebar-nav a {
    font-size: 18px;
  }
}

.image-selector {
  width: 50%;
  margin: auto;
  height: 160px;
  position: relative;
}
.image-selector .img {
  width: 100%;
  max-height: 125px;
  object-fit: contain;
}
.image-selector .left-arrow {
  position: absolute;
  top: 38%;
  left: 5px;
  width: 22px;
  cursor: pointer;
}
.image-selector .right-arrow {
  position: absolute;
  top: 38%;
  right: 5px;
  width: 22px;
  cursor: pointer;
}
.image-selector .img-addons {
  background: #17a2b8;
  height: 30px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.image-selector .img-addons:active {
  background: #1491a5;
}
.image-selector .icon-img {
  width: 20px;
}
.image-selector .img-remove {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 10px;
}

.table {
  font-size: 0.75rem;
}
.table .table-cell {
  vertical-align: middle;
}

.file-link {
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  line-height: 1;
}

.nav-item {
  cursor: pointer;
}

.nav-link.active {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.container-fluid {
  width: 80%;
  margin-left: 15%;
}

.chat-messages {
  border: 1px solid rgba(0, 0, 0, 0.05);
  height: 320px;
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  overflow-y: scroll;
  flex: 1;
}

.message-box-holder {
  width: 100%;
  margin: 0 0 15px;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.message-sender {
  font-size: 12px;
  margin: 0 0 15px;
  color: #30649c;
  align-self: flex-start;
}

.message-box {
  padding: 6px 10px;
  border-radius: 6px 0 6px 0;
  position: relative;
  background: rgba(100, 170, 0, 0.1);
  border: 2px solid rgba(100, 170, 0, 0.1);
  color: #6c6c6c;
  font-size: 12px;
}

.message-box:after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-top: 10px solid rgba(100, 170, 0, 0.2);
  border-right: none;
  bottom: -22px;
  right: 10px;
}

.message-partner {
  background: rgba(0, 114, 135, 0.1);
  border: 2px solid rgba(0, 114, 135, 0.1);
  align-self: flex-start;
}

.message-partner:after {
  right: auto;
  bottom: auto;
  top: -22px;
  left: 9px;
  border: 10px solid transparent;
  border-bottom: 10px solid rgba(0, 114, 135, 0.2);
  border-left: none;
}

/* Tooltip text */
.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.tooltiptext-right {
  top: -5px;
  left: 105%;
}

.tooltiptext-left {
  top: -5px;
  right: 105%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.message-box:hover .tooltiptext {
  visibility: visible;
}

input[type="checkbox"] {
  transform: scale(1.5);
}
